import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useProductsList() {
  // Use toast
  const toast = useToast();
  const showTable = ref(false);
  const refProductsTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "product_code",
      label: "Mã thuốc",
      sortable: false,
    },
    {
      key: "inventory_info.name",
      label: "Tên Kho",
      sortable: false,
    },
    {
      key: "name",
      label: "Tên thuốc",
      sortable: false,
    },
    { key: "active_ingredient", label: "Hoạt chất", sortable: false },
    { key: "unit_id", label: "Đơn vị", sortable: false },
    { key: "amount", label: "Tồn kho", sortable: false },
    // { key: "avail_qty", label: "Khả dụng", sortable: false },
    {
      key: "registration_number",
      label: "SĐK",
      sortable: false,
    },
    // { key: "lot_number", label: "Số lô", sortable: false },
    { key: "expired_date", label: "Hạn sử dụng", sortable: false },
    {
      key: "supplier",
      label: "Nhà cung cấp",
      sortable: false,
      thStyle: { width: "20%" },
    },
  ];
  const perPage = ref(10);
  const totalProducts = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const outOfStockFilter = ref(null);
  const statusInventoryFilter = ref(null);
  const productBrands = ref([]);
  const productCategories = ref([]);
  const productData = ref([]);
  const totalPage = ref(0);
  const inventories = ref([]);
  const inventoryInfo = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refProductsTable.value
      ? refProductsTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    };
  });

  const refetchData = () => {
    refProductsTable.value.refresh();
  };
  const fetchBrands = (ctx, callback) => {
    store.dispatch("phamarProductInventory/fetchBrands").then((res) => {
      productBrands.value = res.data.data.map((x) => ({
        label: x.Name,
        value: x.ID,
      }));
    });
  };
  const fetchCategories = (ctx, callback) => {
    store.dispatch("phamarProductInventory/fetchCategories").then((res) => {
      productCategories.value = res.data.data.map((x) => ({
        label: x.Name,
        value: x.ID,
      }));
    });
  };
  fetchBrands();
  fetchCategories();
  const fetchPharmaProducts = (ctx, callback) => {
    const params = {
      inventory_id: inventoryInfo.value?.id,
      keyword: searchQuery.value,
      page_num: currentPage.value,
      page_size: perPage.value,
      sort_by: "id",
      order: "desc",
    };

    if (statusInventoryFilter.value) {
      params.type = statusInventoryFilter.value;
    }
    store
      .dispatch("phamarProductInventory/fetchPharmaProductsInventory", {
        id: inventoryInfo.value?.id,
        params,
      })
      .then((response) => {
        const products = response.data.data;
        totalProducts.value = response.data?.page?.total;
        callback(products);
        productData.value = response.data.data;
        totalPage.value = response.data?.page?.total_page;
      })

      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching tags list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const fetchPharmaInventories = async (search) => {
    const params = {
      keyword: search,
      page_size: 1000,
      page_num: 1,
      org_id: Vue.prototype.$pharmacyInfo.id,
    };

    try {
      store
        .dispatch("inventoryManager/fetchInventories", params)
        .then((response) => {
          inventories.value = response.data?.data || [];
          inventoryInfo.value =
            inventories.value?.length > 0 ? inventories.value[0] : null;
        });
    } catch (error) {
      console.log(error);
      toast({
        component: ToastificationContent,
        props: {
          title: "Có lỗi khi lấy dữ liệu kho",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };
  const timeout = ref(null);
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      outOfStockFilter,
      statusInventoryFilter,
      inventoryInfo,
    ],
    () => {
      showTable.value = true;
      clearTimeout(timeout.value);
      timeout.value = setTimeout(() => {
        refetchData();
        showTable.value = false;
      }, 1000);
    }
  );

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchPharmaProducts,
    tableColumns,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductsTable,
    refetchData,
    productBrands,
    productCategories,
    statusInventoryFilter,
    productData,
    totalPage,
    inventories,
    inventoryInfo,
    fetchPharmaInventories,
    // Extra
  };
}
